<template>
  <div>
    <CSBreadcrumb v-if="previousRoute.name == 'propertyData'"/>
    <div class="filter-panel">
      <CSSelect style="width: 210px; margin-right: 0;">
        <el-date-picker
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            v-model="queryParams.startDate"
            :editable="false"
            placeholder="选择日期">
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect style="width: 210px;">
        <el-date-picker
            type="datetime"
            v-model="queryParams.endDate"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            :editable="false"
            placeholder="选择日期">
        </el-date-picker>
      </CSSelect>
      <input type="text" class="cs-input" placeholder="搜索报告名称"
        style="margin-left: 0;" v-model="queryParams.search"
      >
      <CSSelect>
        <select v-model="queryParams.isQualified">
          <option value="">检查结果不限</option>
          <option :value="1">合格</option>
          <option :value="0">不合格</option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.isSubmit">
          <option value="">提交状态不限</option>
          <option :value="1">已提交</option>
          <option :value="0">未提交</option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.isFinish">
          <option value="">整改状态不限</option>
          <option :value="1">已完成</option>
          <option :value="0">未完成</option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 30px;">
        <select v-model="queryParams.createUser">
          <option value="">全部创建人</option>
          <option v-for="staff in staffList" :key="staff.id"
                  :value="staff.id">
            {{staff.name}}
          </option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getReportList()">查询</button>
    </div>

    <div class="result-panel">
        <CSTable :thead-top="filterHeight">
            <template v-slot:header>
                <div class="table-header-panel text-right" v-if="$route.params.return != 1">
                    <button class="btn btn-primary sticky-right" @click="$router.push({name: 'qualityReportAdd'})">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-menujiahao"></use>
                        </svg>
                        品质检查报告
                    </button>
                </div>
            </template>
            <template v-slot:thead>
                <tr>
                    <th>保存/提交时间</th>
                    <th>报告名称</th>
                    <th>报告内容</th>
                    <th>检查结果</th>
                    <th>提交状态</th>
                    <th>整改状态</th>
                    <th>创建人</th>
                    <th>操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <template v-if="reportList && reportList.length > 0">
                    <tr v-for="report in reportList" :key="report.id">
                        <td class="date-td">{{ report.submitTime || '-' }}</td>
                        <td>{{ report.name || '-' }}</td>
                        <td>
                            <span class="allow-click" @click="toReportDetail(report.isSubmit ? 2 : 1, report.id)">查看</span>
                        </td>
                        <td>{{report.isQualified ? '合格' : '不合格'}}</td>
                        <td>{{report.isSubmit ? '已提交' : '未提交' }}</td>
                        <td>
                            <template v-if="report.isFinish !== undefined">
                                {{report.isFinish ? '已完成' : '未完成'}}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td>{{report.createUserName || '-'}}</td>
                        <td>
                            <div class="btn-group" v-if="staffInfo.id === report.createUser && !report.isSubmit">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu" style="min-width: 60px;">
                                    <li @click="toReportDetail(1, report.id)"><a>编辑</a></li>
                                    <li @click="deleteReport(report.id)"><a>删除</a></li>
                                </ul>
                            </div>
                            <div v-else style="height: 35px;">-</div>
                        </td>
                    </tr>
                </template>
            </template>
        </CSTable>
      <Pagination />
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";

import {deleteReportUrl, queryDepartmentStaffUrl, queryDepartmentUrl, queryReportUrl} from '@/requestUrl/index';
import {DEPARTMENT_TYPES, ORG_LEVEL, STORAGE_KEY} from "@/constant";
import dayjs from "dayjs";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTable from "@/components/common/CSTable";
export default {
  name: "QualityReport",
  components: {
      CSTable,
    CSBreadcrumb,
    CSSelect,
    Pagination,
  },
  data() {
    return {
        filterHeight: 0,
      queryParams: {
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD') + ' 00:00',
        endDate: dayjs().format('YYYY-MM-DD') + ' 23:59',
        search: '',
        isQualified: '',
        isSubmit: '',
        isFinish: '',
        createUser: '',
      },
      reportList: [],
      staffList: [],
      staffInfo: this.$vc.getCurrentStaffInfo(),
      previousRoute: {},
    }
  },
  async created() {
    window.addEventListener("keydown",this.getReportListDown);
    const staffInfo = this.$vc.getCurrentStaffInfo();
    const departmentList = await this.queryDepartmentList(ORG_LEVEL.DEPARTMENT, 1, 100, staffInfo.companyCode, DEPARTMENT_TYPES.QUALITY);
    this.staffList = await this.getStaffList(departmentList[0]?.code);
    this.getReportList();
    this.$vc.on(this.$route.path, 'pagination_page', 'event', page => {
      this.getReportList(page);
    })
    this.previousRoute = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY))[0];
  },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
  destroyed(){
    window.removeEventListener("keydown",this.getReportListDown);
  },
  deactivated(){
    window.removeEventListener("keydown",this.getReportListDown);
  },
  methods: {
    getReportListDown(e){
      if(e.keyCode == 13){
        this.getReportList();
      }
    },
    /**
     * 跳转到品质报告详情页面
     * @param {Number} type 1: 编辑报告    2：查看报告
     * @param {Number} id 品质报告id
     * */
    toReportDetail(type, id) {
      // console.log(8777,id);
      this.$router.push({name: 'QualityReportDetail', params: {type, id}});
    },
    /**
     * 删除品质报告
     * @param {Number} id 品质报告id
     * */
    deleteReport(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确认删除吗？',
        onConfirm: () => {
          this.$fly.get(deleteReportUrl, {
            id
          })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('删除成功');
            this.getReportList();
            this.$CSDialog.instance.closeDialog();
          })
        }
      })
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {NUmber} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
        level = ORG_LEVEL.COMPANY,
        pageNo = 1,
        pageSize = 100,
        parentCode,
        dutyType  = ''
    ) {
      return this.$fly
          .post(queryDepartmentUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            code: "",
            name: "",
            dutyType,
            parentCode,
            level,
            pageNo,
            pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
          })
          .then((res) => {
            if (res.code !== 0) {
              return [];
            }

            return res.data;
          });
    },
    // 获取员工列表
    getStaffList(departmentCode) {
      return this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            return res.data;
          });
    },
    // 获取品质报告
    getReportList(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryReportUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        ...this.queryParams,
        pageNo,
        pageSize
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        if (pageNo === 1) {
          this.$vc.emit(this.$route.path, 'pagination', 'init', {
            total:res.data.total,
            currentPage: pageNo,
            pageSize,
          })
        }
        this.reportList = res.data.datas;
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.filter-panel
  .cs-select, .cs-input
    &:not(:last-child)
      margin-right 20px
</style>
